import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { get, isEmpty } from 'lodash';
import { CustomFilters, EntityTierFilter } from '../../../catalog/filters';

export type CatalogReactEntityTierPickerClassKey = 'input';
const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactEntityTierPicker',
  },
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const EntityTierPicker = () => {
  const classes = useStyles();
  const {
    backendEntities,
    filters,
    updateFilters,
    queryParameters: { tiers: tiersParameter },
  } = useEntityList<CustomFilters>();

  const queryParamTiers = useMemo(
    () => [tiersParameter].flat().filter(Boolean) as string[],
    [tiersParameter],
  );

  const [selectedTiers, setSelectedTiers] = useState(
    isEmpty(queryParamTiers)
      ? get(filters, ['tiers', 'values'], [])
      : queryParamTiers,
  );

  useEffect(() => {
    if (queryParamTiers.length) {
      setSelectedTiers(queryParamTiers);
    }
  }, [queryParamTiers]);

  // Get unique values from the selected kind
  const availableTiers = useMemo(
    () =>
      [
        ...new Set(
          backendEntities
            .map((e: Entity) => e.spec?.tier?.toString())
            .filter(Boolean) as string[],
        ),
      ].sort((a, b) => a.localeCompare(b)),
    [backendEntities],
  );

  useEffect(() => {
    updateFilters({
      tiers:
        selectedTiers.length && availableTiers.length
          ? new EntityTierFilter(selectedTiers)
          : undefined,
    });
  }, [selectedTiers, updateFilters, availableTiers]);

  // Don't show "filter tier" if there are no values
  if (!availableTiers.length) return null;

  return (
    <Box pb={1} pt={1}>
      <Typography variant="button" component="label">
        Tier
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={availableTiers}
          value={selectedTiers}
          onChange={(_: object, value: string[]) => setSelectedTiers(value)}
          renderOption={(option, { selected }) => (
            <FormControlLabel
              control={
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
              }
              label={option}
            />
          )}
          size="small"
          popupIcon={<ExpandMoreIcon data-testid="tier-picker-expand" />}
          renderInput={params => (
            <TextField
              {...params}
              className={classes.input}
              variant="outlined"
            />
          )}
        />
      </Typography>
    </Box>
  );
};

import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningOutline from '@material-ui/icons/ReportProblemOutlined';
import { palette } from '../ColorTheme/ScorecardStatusTheme';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  ok: {
    color: palette.success.main,
  },
  warn: {
    color: palette.warning.main,
  },
  error: {
    color: palette.error.main,
  },
  statusIconSize: {
    width: '0.8em',
    height: '0.8em',
  },
  statusIcon: {
    flexShrink: 0,
    position: 'relative',
    top: '0.125em',
  },
}));

export const StatusOK = () => {
  const classes = useStyles();
  return (
    <Tooltip title="Good Job!">
      <IconButton
        className={classNames(
          classes.warn,
          classes.statusIconSize,
          classes.statusIcon,
        )}
      >
        <CheckCircleOutlineIcon
          className={classNames(
            classes.ok,
            classes.statusIconSize,
            classes.statusIcon,
          )}
        />
      </IconButton>
    </Tooltip>
  );
};

export const StatusError = () => {
  const classes = useStyles();
  return (
    <Tooltip title="Error. Review Check Details">
      <IconButton
        className={classNames(
          classes.warn,
          classes.statusIconSize,
          classes.statusIcon,
        )}
      >
        <ErrorOutlineIcon
          className={classNames(
            classes.error,
            classes.statusIconSize,
            classes.statusIcon,
          )}
        />
      </IconButton>
    </Tooltip>
  );
};

export const StatusWarning = ({
  tooltipTitle = 'Warning, missing some configuration',
}: {
  tooltipTitle?: string;
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        className={classNames(
          classes.warn,
          classes.statusIconSize,
          classes.statusIcon,
        )}
      >
        <WarningOutline
          className={classNames(
            classes.warn,
            classes.statusIconSize,
            classes.statusIcon,
          )}
        />
      </IconButton>
    </Tooltip>
  );
};

export const StatusWarningOptional = () => {
  return (
    <StatusWarning tooltipTitle="Optional Check. It doesn't compute in the global score" />
  );
};

import { makeStyles } from '@material-ui/core';
import { palette } from '../ColorTheme/ScorecardStatusTheme';

interface PercentageStylingProps {
  result: string;
  showPercentage: boolean;
}

export const usePercentageStyling = makeStyles(theme => ({
  circleIndeterminate: {
    color: (props: PercentageStylingProps) => {
      const { result } = props;

      if (result === 'SUCCESS') {
        return palette.success.main;
      }

      if (result === 'WARN') {
        return palette.warning.main;
      }

      return palette.error.main;
    },
  },
  label: (props: PercentageStylingProps) => {
    if (props.showPercentage) {
      return {};
    }

    return {
      padding: '2px',
      background: 'rgba(255,255,255,0.8)',
      borderRadius: theme.shape.borderRadius,
    };
  },
}));

import { Grid } from '@material-ui/core';
import { ScorecardInfo } from './ScorecardInfo';
import React, { useState } from 'react';
import { ScorecardGroupsContent, CheckResult } from './types';
import { ScorecardToggleButton } from './ScorecardToggleButton';
import { defineCheckStatus, setResultFromStatus } from './TemplatingEngine';
import { CircularProgressWithLabel } from '../catalog/CustomScorecardCard/CircularProgressLabel';
import { usePercentageStyling } from './Percentage/percentageStyling';

interface ScorecardPageProps {
  groupsContent: ScorecardGroupsContent[];
}

const GlobalPercentage = (props: { progress: number }) => {
  const { progress } = props;

  const options = {
    result: 'SUCCESS',
    showPercentage: true,
  };
  if (progress < 80 && progress >= 40) options.result = 'WARN';
  if (progress < 40) options.result = 'ERROR';

  const styles = usePercentageStyling(options);
  return (
    <CircularProgressWithLabel
      variant="determinate"
      value={progress}
      className={styles.circleIndeterminate}
    />
  );
};

export const ScorecardPage = (props: ScorecardPageProps) => {
  const { groupsContent } = props;

  groupsContent.forEach(value => {
    const groupedChecks = [...value.successfulChecks, ...value.failedChecks];
    const updatedCheckResults: CheckResult[] = [];

    groupedChecks.forEach(check => {
      defineCheckStatus(check);
      updatedCheckResults.push(setResultFromStatus(check));
    });

    value.successfulChecks = updatedCheckResults.filter(check => check.result);
    value.failedChecks = updatedCheckResults.filter(check => !check.result);

    if (value.successfulChecks.length > 0) {
      value.okPercentage =
        100 * (value.successfulChecks.length / groupedChecks.length);
    }
  });

  const [groupVisibility, setGroupVisibility] = useState(
    new Array(groupsContent.length).fill(true),
  );

  return (
    <Grid container spacing={3} alignItems="stretch">
      {groupsContent.map((value, index) => {
        const allChecks = [
          ...value.successfulChecks,
          ...value.failedChecks,
        ] as CheckResult[];

        return (
          <Grid key={value.title} item xs={12} md={6} lg={4} xl>
            <ScorecardInfo
              title={value.title}
              actions={
                value.features.showPercentage && (
                  <GlobalPercentage progress={value.okPercentage} />
                )
              }
              subheader={
                value.features.showOnlyFailed && (
                  <ScorecardToggleButton
                    successfullChecks={value.successfulChecks.length}
                    failedChecks={value.failedChecks.length}
                    showAllChecks={!groupVisibility[index]}
                    onToggleClick={() => {
                      const updatedGroupVisibility = [...groupVisibility];
                      updatedGroupVisibility[index] = !groupVisibility[index];
                      setGroupVisibility(updatedGroupVisibility);
                    }}
                    groupIndex={index}
                  />
                )
              }
              description={value.description}
              checkResults={
                value.features.showOnlyFailed &&
                !groupVisibility[index] &&
                value.failedChecks.length > 0
                  ? value.failedChecks
                  : allChecks
              }
              group={value}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

import { z, type ZodType, type ZodTypeDef } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';

const TeamMemberRolePickerFieldSchema = makeFieldSchemaFromZod(
  // @ts-ignore
  z.string() as ZodType<any, ZodTypeDef, any>,
);

export const TeamMemberRolePickerSchema =
  TeamMemberRolePickerFieldSchema.schema;

export type TeamMemberRolePickerProps =
  typeof TeamMemberRolePickerFieldSchema.type;

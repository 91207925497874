import React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { catalogImportPlugin } from '@backstage/plugin-catalog-import';
import { scaffolderPlugin, ScaffolderPage } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';

import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { datadogRum } from '@datadog/browser-rum';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import * as plugins from './plugins';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from './components/catalogGraph/catalogPage';
import { Direction } from '@backstage/plugin-catalog-graph';
import {
  configApiRef,
  useApi,
  discoveryApiRef,
  IdentityApi,
  microsoftAuthApiRef,
  githubAuthApiRef,
} from '@backstage/core-plugin-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { OpsgeniePage } from '@celonis/backstage-plugin-opsgenie';

import { PlaylistIndexPage, PlaylistPage } from '@backstage/plugin-playlist';

import { setTokenCookie } from './cookieAuth';

import { AnnouncementsPage } from '@celonis/backstage-plugin-announcements';

import {
  ExpandableNavigation,
  ReportIssue,
  TextSize,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { useDatadogRum } from './datadogRum';

import type { FC } from 'react';
import { Icon } from '@iconify/react';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CHILD_OF,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PARENT_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { GraphNode } from './components/catalogGraph/CatalogGraphNode';
import {
  OrgIcon,
  PillarIcon,
  InternalGroupIcon,
  SubtribeIcon,
  TribeIcon,
} from './assets/icons/CustomIcons';
import { UniqueEntityNameFieldExtension } from './components/scaffolder/UniqueEntityName';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { NextTemplateCard } from './components/Create';
import {
  catalogColumns,
  CatalogPageAPI,
  CatalogPage,
  AdminExplorerPage,
  APIExplorerPage,
} from './components/catalogPage';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { createPermission } from '@backstage/plugin-permission-common';
import {
  allCatalogColumns,
  audienceColumn,
} from './components/catalogPage/CatalogPage/catalogColumns';
import { EntityLinkTypePickerFieldExtension } from './components/scaffolder/EntityLinkTypes/extensions';
import { TeamMemberRolePickerFieldExtension } from './components/scaffolder/TeamMemberRolePicker';

const adminViewPermission = createPermission({
  name: 'adminView',
  attributes: {},
});

const microsoftProvider: SignInProviderConfig = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft Active Directory',
  message:
    'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const githubProvider: SignInProviderConfig = {
  id: 'github-auth-provider',
  title: 'GitHub',
  message: 'Sign in using GitHub',
  apiRef: githubAuthApiRef,
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          providers={
            useApi(configApiRef).getString('auth.environment') === 'development'
              ? [githubProvider, microsoftProvider]
              : [microsoftProvider]
          }
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            identityApi.getProfileInfo().then(profile => {
              datadogRum.setUser({
                id: profile.email,
                email: profile.email,
                name: profile.displayName,
              });
            });

            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  icons: {
    slack: () => <Icon icon="mdi:slack" width={24} />,
    datadog: () => (
      <Icon icon="simple-icons:datadog" width="1.7em" height="1.7em" />
    ),
    opsgenie: () => (
      <Icon icon="simple-icons:opsgenie" width="1.7em" height="1.7em" />
    ),
    runbook: () => (
      <Icon icon="fluent:tasks-app-24-filled" width="1.7em" height="1.7em" />
    ),
    jira: () => <Icon icon="mdi:jira" width="1.7em" height="1.7em" />,
    subtribe: SubtribeIcon,
    tribe: TribeIcon,
    pillar: PillarIcon,
    org: OrgIcon,
    internalGroup: InternalGroupIcon,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const apiColumns = [
  ...catalogColumns.slice(0, 5),
  audienceColumn,
  ...catalogColumns.slice(5),
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    ;
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CatalogPage
        columns={allCatalogColumns}
        allowedKinds={[
          'component',
          'resource',
          'api',
          'user',
          'group',
          'domain',
          'system',
        ]}
      />
    </Route>
    <Route path="/api-docs" element={<APIExplorerPage />}>
      <CatalogPageAPI
        columns={apiColumns}
        allowedKinds={['api']}
        initialKind="api"
      />
    </Route>
    <Route
      path="/admin/catalog"
      element={
        <RequirePermission permission={adminViewPermission}>
          <AdminExplorerPage columns={allCatalogColumns} />
        </RequirePermission>
      }
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/playlist" element={<PlaylistIndexPage />} />
    <Route path="/playlist/:playlistId" element={<PlaylistPage />} />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
        <Mermaid
          darkConfig={{ theme: 'dark' }}
          lightConfig={{ theme: 'forest' }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route
      path="/create"
      element={
        <ScaffolderPage
          components={{
            TemplateCardComponent: NextTemplateCard,
          }}
          templateFilter={
            template => !template.metadata?.tags?.includes('beta')// exclude beta template for now.
          }
          groups={[
            {
              title: 'Getting Started',
              filter: entity =>
                entity?.metadata?.tags?.includes('getting-started') ?? false,
            },
            {
              title: 'Techdocs',
              filter: entity =>
                entity?.metadata?.tags?.includes('techdocs') ?? false,
            },
            {
              title: 'Extend Group Information',
              filter: entity => entity.spec.type === 'Backstage / Groups',
            },
            {
              title: 'General Entity Enhancements',
              filter: entity =>
                (entity?.metadata?.tags?.includes('enhance') &&
                  !entity?.metadata?.tags?.includes('group')) ??
                false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <UniqueEntityNameFieldExtension />
        <EntityLinkTypePickerFieldExtension />
        <TeamMemberRolePickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/org-graph"
      element={
        <CatalogGraphPage
          initialState={{
            showFilters: false,
            selectedKinds: ['user', 'group'],
            maxDepth: 1,
            rootEntityRefs: ['group:org-product-and-engineering'],
            selectedRelations: [RELATION_PARENT_OF, RELATION_CHILD_OF],
            direction: Direction.LEFT_RIGHT,
          }}
          renderNode={GraphNode}
        />
      }
    />
    <Route
      path="catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            showFilters: false,
            selectedKinds: [
              'api',
              'component',
              'domain',
              'resource',
              'system',
              'group',
            ],
            selectedRelations: [
              RELATION_OWNED_BY,
              RELATION_OWNER_OF,
              RELATION_PART_OF,
              RELATION_HAS_PART,
              RELATION_API_CONSUMED_BY,
              RELATION_API_PROVIDED_BY,
              RELATION_CONSUMES_API,
              RELATION_PROVIDES_API,
              RELATION_DEPENDENCY_OF,
              RELATION_DEPENDS_ON,
            ],
            direction: Direction.LEFT_RIGHT,
            maxDepth: 1,
          }}
          renderNode={GraphNode}
        />
      }
    />
    <Route
      path="/opsgenie"
      element={<OpsgeniePage onCallListCardsCount={10} onCallUseScheduleName />}
    />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/admin/catalog-unprocessed-entities"
      element={
        <RequirePermission permission={adminViewPermission}>
          <CatalogUnprocessedEntitiesPage />
        </RequirePermission>
      }
    />
    ;
  </FlatRoutes>
);

const DatadogWrapper: FC<React.PropsWithChildren> = ({ children }) => {
  useDatadogRum();

  return <>{children}</>;
};

const App = () => {
  return (
    <AppProvider>
      <DatadogWrapper>
        <AlertDisplay />
        <OAuthRequestDialog />
        <AppRouter>
          <Root>{routes}</Root>
        </AppRouter>
      </DatadogWrapper>
    </AppProvider>
  );
};

export default App;

import React, { useEffect, useMemo, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { TeamMemberRolePickerProps } from './schema';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';
import { Progress } from '@backstage/core-components';
import { Autocomplete } from '@material-ui/lab';
import { stringifyEntityRef, Entity } from '@backstage/catalog-model';
import { get, isNil } from 'lodash';
import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
import { scaffolderTranslationRef } from '../translation';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

type PickerFormat = {
  label: string;
  entityRef: string;
};

export const TeamMemberRolePicker = (props: TeamMemberRolePickerProps) => {
  const { t } = useTranslationRef(scaffolderTranslationRef);

  const {
    onChange,
    required,
    rawErrors,
    formContext,
    formData,
    schema: {
      title = t('fields.teamMemberRolePicker.title'),
      description = t('fields.teamMemberRolePicker.description'),
    },
    uiSchema,
  } = props;
  const {
    formData: { groupEntityRef },
  } = formContext;

  // Get the field we want to select the role from. Defaults to engineering owner
  const roleField =
    (uiSchema['ui:options']?.roleField as string) ?? 'spec.roles.engOwner';

  // Query Catalog to get the selected Group Entity & All User Entities
  const catalogApi = useApi(catalogApiRef);
  const { value: data, loading } = useAsync(async () => {
    const groupEntity = await catalogApi.getEntityByRef(groupEntityRef);

    const fields = [
      'metadata.name',
      'metadata.namespace',
      'metadata.title',
      'kind',
    ];
    const { items } = await catalogApi.getEntities({
      filter: { kind: 'User' },
      fields,
    });

    return { groupEntity, catalogEntities: items };
  });

  // Define how we want to use the entities
  const userEntities = (data?.catalogEntities ?? []).map((entity: Entity) => {
    return {
      label: isNil(entity.metadata.title)
        ? entity.metadata.name
        : `${entity.metadata.title} - ${entity.metadata.name}`,
      entityRef: stringifyEntityRef(entity),
    };
  });

  // Read role from the group entity spec and define that as the default value
  const defaultRef = get(data?.groupEntity, roleField);
  const defaultValue: PickerFormat = useMemo(
    () => ({
      label: defaultRef,
      entityRef: defaultRef,
    }),
    [defaultRef],
  );

  // Manage State for the checkbox and autocomplete
  const setStateForCheckbox = () => formData ?? false;
  const setStateForUser = () =>
    typeof formData === 'string' ? formData : defaultValue.entityRef;
  const [isCheckboxSelected, setIsCheckboxSelected] =
    useState<boolean>(setStateForCheckbox);
  const [selectedUser, setSelectedUser] =
    useState<string>(setStateForUser);

  /**
   * Handle the change event for the user selection
   * Use new value or default value if no value is selected
   */
  const handleSelectedUserChange = (
    _event: any,
    newValue: PickerFormat | null,
  ) => {
    const entityRef = newValue?.entityRef ?? defaultValue.entityRef;
    setSelectedUser(entityRef);
    onChange(entityRef);
  };

  /*
   * Handle the checkbox change event
   * If the checkbox is unchecked, set the selected user to empty
   * If the checkbox is checked, set the selected user
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxSelected(event.target.checked);
    if (!event.target.checked) {
      setSelectedUser('');
      onChange('');
    } else {
      onChange(selectedUser);
    }
  };

  // Set the checkbox to checked if the default value is set
  useEffect(() => {
    if (defaultValue.entityRef) {
      setIsCheckboxSelected(true);
    }
  }, [defaultValue]);
  // Set the one value if there is only one entity and the default value is not set
  useEffect(() => {
    if (userEntities.length === 1) {
      const singleEntity = userEntities[0].entityRef;
      onChange(singleEntity);
      setSelectedUser(singleEntity);
    } else if (!formData) {
      setSelectedUser(defaultValue.entityRef);
      if (isCheckboxSelected) {
        onChange(defaultValue.entityRef);
      } else {
        onChange('');
      }
    }
  }, [userEntities, onChange, formData, defaultValue, isCheckboxSelected]);

  // Required to wait for the data to be fetched
  if (loading) return <Progress />;

  // Render the component
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isCheckboxSelected}
            onChange={handleCheckboxChange}
            name="showAutocomplete"
            color="primary"
          />
        }
        label={`Set ${title}?`}
      />
      {isCheckboxSelected && (
        <Autocomplete
          options={userEntities}
          getOptionLabel={option => option.label}
          value={
            userEntities.find(option => option.entityRef === selectedUser) ??
            null
          }
          onChange={handleSelectedUserChange}
          renderInput={params => (
            <TextField
              {...params}
              label={title}
              helperText={description}
              variant="outlined"
              margin="dense"
              FormHelperTextProps={{
                margin: 'dense',
                style: { marginLeft: 0 },
              }}
              InputProps={params.InputProps}
            />
          )}
        />
      )}
    </FormControl>
  );
};

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { TeamMemberRolePicker } from './TeamMemberRolePicker';
import { TeamMemberRolePickerSchema } from './schema';

export const TeamMemberRolePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'TeamMemberRolePicker',
    component: TeamMemberRolePicker,
    schema: TeamMemberRolePickerSchema,
  }),
);
